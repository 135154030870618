import * as Sentry from "@sentry/nextjs";
import Error from "next/error";

const CustomErrorComponent = props => {
  return <Error statusCode={props.statusCode} />;
};

CustomErrorComponent.getInitialProps = async contextData => {
  await Sentry.captureUnderscoreErrorException(contextData);
  return Error.getInitialProps(contextData);
};

export default CustomErrorComponent;
